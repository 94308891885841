var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"designideas-carsouel"},[_c('Carousel',{attrs:{"settings":_vm.settings}},_vm._l((_vm.data),function(ehd){return _c('div',{key:ehd.id},[_c('div',{staticClass:"designideas-card"},[_c('div',{staticClass:"img-wrapper"},[(_vm.onlyKitchen)?_c('router-link',{attrs:{"to":{
                            name: 'EHDPage',
                            params: { id: ehd.id },
                            query: { ok: true },
                        }}},[_c('img',{attrs:{"src":ehd.collage_image
                                    ? ehd.collage_image
                                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'}})]):_c('router-link',{attrs:{"to":{
                            name: 'EHDPage',
                            params: { id: ehd.id },
                        }}},[_c('img',{attrs:{"src":ehd.collage_image
                                    ? ehd.collage_image
                                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'}})]),_c('router-link',{staticClass:"designed-by",attrs:{"to":{
                            name: 'DesignerDetailPortfolio',
                            params: {
                                id: ehd.designer_id,
                            },
                        }}},[_c('div',{staticClass:"designer-img-wrapper"},[_c('img',{staticClass:"designer-img",attrs:{"src":ehd.designer_image
                                        ? ehd.designer_image
                                        : require('assets/images/default-designer.png'),"alt":""}})]),_c('div',{staticClass:"name"},[_c('p',[_vm._v(_vm._s(ehd.designer_name))])])])],1),_c('div',{staticClass:"designideas-details"},[_c('div',{staticClass:"inner-details"},[_c('p',{staticClass:"bold-detail"},[_vm._v(" "+_vm._s(ehd.theme)+" ")]),_c('p',{staticClass:"bold-detail",class:_vm.requiredDomain == false ? 'blur' : ''},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem(ehd.price))+" ")])]),_c('div',{staticClass:"inner-details"},[_c('p',[_vm._v(" "+_vm._s(ehd.floorplan.slice(0, 14))+" "),(ehd.floorplan.length > 14)?_c('span',[_vm._v(" ... ")]):_vm._e()]),_c('p',[_vm._v(" "+_vm._s(ehd.project.slice(0, 14))+" "),(ehd.project.length > 14)?_c('span',[_vm._v(" ... ")]):_vm._e()])])])])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }