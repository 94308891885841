<template>
    <div class="mydesignideas">
        <template
            v-if="
                projectData.is_sq_completed == true &&
                    projectData.is_pq_completed == true
            "
        >
            <!-- <div class="mudi-carousels">
                <div
                    class="carousel"
                    v-if="designIdeas.recommended.data.length > 0"
                >
                    <div class="head">
                        <div class="carousel-name">
                            {{ designIdeas.recommended.title }}
                        </div>
                    </div>
                    <div class="recommended_scene">
                        <Carousel :settings="settings">
                            <div
                                class="mudi-card"
                                v-for="(card, index) in designIdeas.recommended
                                    .data"
                                :key="card + '_' + index"
                            >
                                <template v-if="card.type == 'EHD'">
                                    <MyDesignIdeasCard
                                        :s_img="
                                            Object.keys(card.image).length > 0
                                                ? card.image.s_image
                                                : require('assets/images/default-image.png')
                                        "
                                        :d_img="card.designer_image.p_image"
                                        :designerName="card.designer_name"
                                        :cost="card.price"
                                        :h_style="card.theme"
                                        :size="card.floorplan"
                                        type="EHD"
                                    />
                                </template>
                                <template v-if="card.type == 'Scene'">
                                    <MyDesignIdeasCard
                                        :s_img="
                                            card.scene_image_data_list.length >
                                            0
                                                ? card.scene_image_data_list[0]
                                                      .scene_image_url.p_image
                                                : require('assets/images/default-image.png')
                                        "
                                        :d_img="card.designer_image.p_image"
                                        :designerName="card.designer_name"
                                        :cost="card.price"
                                        :h_style="card.theme_name"
                                        :size="card.property_floorplan_name"
                                    />
                                </template>
                            </div>
                        </Carousel>
                    </div>
                </div>
                <template
                    v-if="
                        projectData.is_sq_completed == true &&
                            projectData.is_pq_completed == true
                    "
                >
                    <div
                        class="carousel"
                        v-if="designIdeas.theme.data.length > 0"
                    >
                        <div class="head">
                            <div class="carousel-name">
                                {{ designIdeas.theme.title }}
                            </div>
                        </div>
                        <div class="budget_scene">
                            <Carousel :settings="settings">
                                <div
                                    class="mudi-card"
                                    v-for="(card, index) in designIdeas.theme
                                        .data"
                                    :key="card + '_' + index"
                                >
                                    <template v-if="card.type == 'EHD'">
                                        <MyDesignIdeasCard
                                            :s_img="
                                                Object.keys(card.image).length >
                                                0
                                                    ? card.image.s_image
                                                    : require('assets/images/default-image.png')
                                            "
                                            :d_img="card.designer_image.p_image"
                                            :designerName="card.designer_name"
                                            :cost="card.price"
                                            :h_style="card.theme"
                                            :size="card.floorplan"
                                            type="EHD"
                                        />
                                    </template>
                                    <template v-if="card.type == 'Scene'">
                                        <MyDesignIdeasCard
                                            :s_img="
                                                card.scene_image_data_list
                                                    .length > 0
                                                    ? card
                                                          .scene_image_data_list[0]
                                                          .scene_image_url
                                                          .p_image
                                                    : require('assets/images/default-image.png')
                                            "
                                            :d_img="card.designer_image.p_image"
                                            :designerName="card.designer_name"
                                            :cost="card.price"
                                            :h_style="card.theme_name"
                                            :size="card.property_floorplan_name"
                                        />
                                    </template>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                    <div
                        class="carousel"
                        v-if="designIdeas.space.data.length > 0"
                    >
                        <div class="head">
                            <div class="carousel-name">
                                {{ designIdeas.space.title }}
                            </div>
                        </div>
                        <div class="theme_scene">
                            <Carousel :settings="settings">
                                <div
                                    class="mudi-card"
                                    v-for="(card, index) in designIdeas.space
                                        .data"
                                    :key="card + '_' + index"
                                >
                                    <template v-if="card.type == 'EHD'">
                                        <MyDesignIdeasCard
                                            :s_img="
                                                Object.keys(card.image).length >
                                                0
                                                    ? card.image.s_image
                                                    : require('assets/images/default-image.png')
                                            "
                                            :d_img="card.designer_image.p_image"
                                            :designerName="card.designer_name"
                                            :cost="card.price"
                                            :h_style="card.theme"
                                            :size="card.floorplan"
                                            type="EHD"
                                        />
                                    </template>
                                    <template v-if="card.type == 'Scene'">
                                        <MyDesignIdeasCard
                                            :s_img="
                                                card.scene_image_data_list
                                                    .length > 0
                                                    ? card
                                                          .scene_image_data_list[0]
                                                          .scene_image_url
                                                          .p_image
                                                    : require('assets/images/default-image.png')
                                            "
                                            :d_img="card.designer_image.p_image"
                                            :designerName="card.designer_name"
                                            :cost="card.price"
                                            :h_style="card.theme_name"
                                            :size="card.property_floorplan_name"
                                        />
                                    </template>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="designideas-onboarding">
                        <p>Please complete onboarding to use this section</p>
                        <a :href="`${url}/?startQuiz=true&survey_type=PQ`">
                            <Button
                                class="onboarding-btn"
                                primary
                                name="Complete Onboarding"
                            />
                        </a>

                        <img
                            class="house-desgin"
                            src="@/assets/images/house.png"
                        />
                    </div>
                </template>
            </div> -->
            <template v-if="Object.keys(designIdeas).length > 0">
                <div class="mudi-foryou">
                    <!-- <div
                        class="mudi-foryou-grid"
                        v-if="designIdeasList.length > 0"
                    >
                        <div
                            v-for="recommended in designIdeasList"
                            :key="recommended.id"
                        >
                            <template v-if="recommended.type == 'EHD'">
                                <MyDesignIdeasCard
                                    :linkEHD="{
                                        name: 'EHDPage',
                                        params: { id: recommended.id },
                                    }"
                                    :s_img="
                                        recommended.image
                                            ? recommended.collage_image
                                            : require('assets/images/default-image1.png')
                                    "
                                    :d_img="recommended.designer_image.p_image"
                                    :designerName="recommended.designer_name"
                                    :cost="
                                        convertToIndianNumberSystem(
                                            recommended.price,
                                        )
                                    "
                                    :h_style="recommended.theme"
                                    :size="recommended.floorplan"
                                    type="EHD"
                                    :linkDesigner="{
                                        name: 'DesignerDetailPortfolio',
                                        params: {
                                            id: recommended.designer_id,
                                        },
                                    }"
                                    :banner="recommended.banner"
                                />
                            </template>
                        </div>
                    </div>
                    <template v-else>
                        <p style="text-align:center; padding: 40px 0;">
                            No personalised data found for you.
                        </p>
                    </template> -->
                    <div v-for="(data, index) in designIdeas.data" :key="index">
                        <template v-if="data.ehd_data.length > 0">
                            <h1 class="header">{{ data.title }}</h1>
                            <DesignIdeasCarousel
                                :data="data.ehd_data"
                                :requiredDomain="requiredDomain"
                                :onlyKitchen="projectData.kitchen_only"
                            />
                        </template>
                    </div>
                </div>
                <!-- <div class="mudi-forothers">
                    <h1 class="header">{{ designIdeas.others.title }}</h1>
                    <div
                        class="mudi-forothers-grid"
                        v-if="designIdeas.others.data.length > 0"
                    >
                        <div
                            v-for="others in designIdeas.others.data"
                            :key="others.id"
                        >
                            <template v-if="others.type == 'EHD'">
                                <MyDesignIdeasCard
                                    :linkEHD="{
                                        name: 'EHDPage',
                                        params: { id: others.id },
                                    }"
                                    :s_img="others.collage_image"
                                    :d_img="others.designer_image.p_image"
                                    :designerName="others.designer_name"
                                    :cost="
                                        convertToIndianNumberSystem(
                                            others.price,
                                        )
                                    "
                                    :h_style="others.theme"
                                    :size="others.floorplan"
                                    type="EHD"
                                    :linkDesigner="{
                                        name: 'DesignerDetailPortfolio',
                                        params: {
                                            id: others.designer_id,
                                        },
                                    }"
                                    :banner="others.banner"
                                />
                            </template>
                        </div>
                    </div>
                    <template v-else>
                        <p style="text-align:center; padding: 40px 0;">
                            No data found
                        </p>
                    </template>
                </div> -->
            </template>
            <template v-else>
                <div class="no-data">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                        width="280"
                        height="340"
                        viewBox="0 0 647.63626 632.17383"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <path
                            d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
                            transform="translate(-276.18187 -133.91309)"
                            fill="#f2f2f2"
                        />
                        <path
                            d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
                            transform="translate(-276.18187 -133.91309)"
                            fill="#3f3d56"
                        />
                        <path
                            d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
                            transform="translate(-276.18187 -133.91309)"
                            fill="#ff6100"
                        />
                        <circle
                            cx="190.15351"
                            cy="24.95465"
                            r="20"
                            fill="#ff6100"
                        />
                        <circle
                            cx="190.15351"
                            cy="24.95465"
                            r="12.66462"
                            fill="#fff"
                        />
                        <path
                            d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
                            transform="translate(-276.18187 -133.91309)"
                            fill="#e6e6e6"
                        />
                        <path
                            d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
                            transform="translate(-276.18187 -133.91309)"
                            fill="#3f3d56"
                        />
                        <path
                            d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
                            transform="translate(-276.18187 -133.91309)"
                            fill="#ff6100"
                        />
                        <circle
                            cx="433.63626"
                            cy="105.17383"
                            r="20"
                            fill="#ff6100"
                        />
                        <circle
                            cx="433.63626"
                            cy="105.17383"
                            r="12.18187"
                            fill="#fff"
                        />
                    </svg>
                    No Data Available
                </div>
            </template>
        </template>

        <template v-else>
            <div class="designideas-onboarding" v-if="loaderShow == false">
                <p>Please complete onboarding to use this section</p>
                <a :href="`${url}?startQuiz=true&survey_type=PQ`">
                    <Button
                        class="onboarding-btn"
                        primary
                        name="Complete Onboarding"
                    />
                </a>
                <img
                    class="house-design"
                    src="@/assets/images/house.png"
                    alt="House"
                />
            </div>
        </template>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader />
            </div>
        </template>
    </div>
</template>

<script>
import ConvertToPrice from 'mixins/ConvertToPrice'
//import MyDesignIdeasCard from 'componentsv2/MyDesignIdeasCard'
import Loader from 'componentsv2/Loader'
import DesignIdeasCarousel from 'componentsv2/DesignIdeasCarousel'
import Button from 'componentsv2/Button'
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import MudiPageModule from 'store/modules/MudiPage'
import loaderHandler from 'mixins/loader'

export default {
    name: 'MyDesignIdeas',
    props: ['url', 'projectData', 'requiredDomain'],
    mixins: [RegisterStoreModule, ConvertToPrice, loaderHandler],
    components: {
        DesignIdeasCarousel,
        //MyDesignIdeasCard,
        Loader,
        Button,
    },
    data() {
        return {
            // settings: {
            //     arrows: true,
            //     focusOnSelect: true,
            //     infinite: false,
            //     speed: 500,
            //     slidesToShow: 3,
            //     slidesToScroll: 2,
            //     touchThreshold: 5,
            //     responsive: [
            //         {
            //             breakpoint: 1280,
            //             settings: {
            //                 slidesToShow: 1,
            //                 slidesToScroll: 1,
            //                 infinite: true,
            //             },
            //         },
            //         {
            //             breakpoint: 1024,
            //             settings: {
            //                 slidesToShow: 1,
            //                 slidesToScroll: 1,
            //                 infinite: true,
            //             },
            //         },
            //         {
            //             breakpoint: 600,
            //             settings: {
            //                 slidesToShow: 1,
            //                 slidesToScroll: 1,
            //             },
            //         },
            //         {
            //             breakpoint: 480,
            //             settings: {
            //                 slidesToShow: 1,
            //                 slidesToScroll: 1,
            //             },
            //         },
            //     ],
            // },
        }
    },
    computed: {
        ...mapState({
            designIdeas: state => state.MudiPageModule.designIdeas,
        }),
    },
    created() {
        this.registerStoreModule('MudiPageModule', MudiPageModule)
        this.fetchDesignIdeas()
            .then(response => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 100)
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 100)
            })
    },
    methods: {
        ...mapActions({
            fetchDesignIdeas: 'fetchDesignIdeas',
        }),
    },
}
</script>

<style lang="scss" scoped>
@import './MyDesignIdeas.scss';
</style>
