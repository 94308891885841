<template>
    <div class="designideas-carsouel">
        <Carousel :settings="settings">
            <div v-for="ehd in data" :key="ehd.id">
                <div class="designideas-card">
                    <div class="img-wrapper">
                        <router-link
                            v-if="onlyKitchen"
                            :to="{
                                name: 'EHDPage',
                                params: { id: ehd.id },
                                query: { ok: true },
                            }"
                        >
                            <img
                                :src="
                                    ehd.collage_image
                                        ? ehd.collage_image
                                        : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                "
                            />
                        </router-link>
                        <router-link
                            v-else
                            :to="{
                                name: 'EHDPage',
                                params: { id: ehd.id },
                            }"
                        >
                            <img
                                :src="
                                    ehd.collage_image
                                        ? ehd.collage_image
                                        : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                "
                            />
                        </router-link>
                        <router-link
                            :to="{
                                name: 'DesignerDetailPortfolio',
                                params: {
                                    id: ehd.designer_id,
                                },
                            }"
                            class="designed-by"
                        >
                            <div class="designer-img-wrapper">
                                <img
                                    class="designer-img"
                                    :src="
                                        ehd.designer_image
                                            ? ehd.designer_image
                                            : require('assets/images/default-designer.png')
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="name">
                                <p>{{ ehd.designer_name }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="designideas-details">
                        <div class="inner-details">
                            <p class="bold-detail">
                                {{ ehd.theme }}
                            </p>
                            <p
                                class="bold-detail"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                ₹{{ convertToIndianNumberSystem(ehd.price) }}
                            </p>
                        </div>
                        <div class="inner-details">
                            <p>
                                {{ ehd.floorplan.slice(0, 14) }}
                                <span v-if="ehd.floorplan.length > 14">
                                    ...
                                </span>
                            </p>
                            <p>
                                {{ ehd.project.slice(0, 14) }}
                                <span v-if="ehd.project.length > 14">
                                    ...
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Carousel>
    </div>
</template>
<script>
import Carousel from 'componentsv2/Carousel'
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name: 'DesignIdeasCarousel',
    components: {
        Carousel,
    },
    mixins: [ConvertToPrice],
    props: {
        data: Array,
        requiredDomain: Boolean,
        onlyKitchen: Boolean,
    },
    data() {
        return {
            settings: {
                arrows: true,
                focusOnSelect: false,
                speed: 500,
                slidesToShow: 3,
                infinite: false,
                slidesToScroll: 1,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
        }
    },
}
</script>
<style lang="scss" scoped>
@import './DesignIdeasCarousel.scss';
</style>
